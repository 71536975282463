
import Vue from 'vue';

export default Vue.extend({
    name: 'Logout',
    methods: {
        exit(): void {
            localStorage.setItem('jwt-token', '');
            this.$router.push({ name: 'login' });
        }
    }
});
