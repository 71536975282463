
import Vue from 'vue';
import AuthLayout from '@/layouts/emptyLayout.vue';
import MainLayout from '@/layouts/mainLayout.vue';

export default Vue.extend({
  name: 'App',
  components: {
    AuthLayout,
    MainLayout
  },
  computed: {
    layout() {
      return this.$route.meta?.layout ?? 'auth-layout';
    }
  },
  created() {
    // @ts-ignore
    this.$vuetify.theme.dark = this.$root.darkTheme;

    this.$store.dispatch('info/getAll');
    this.$store.dispatch('tests/setup');
  }
});
