
import Vue from 'vue';

export default Vue.extend({
    name: 'ErrorDialog',
    data() {
        return {
            dialog: false,
            message: ''
        };
    },
    computed: {
        snackbarVisibility: {
            get(): boolean {
                return this.$store.getters.hasError;
            },
            set(val) {
                this.$store.commit('clearError', val);
            }
        }
    },
    methods: {
        openDialog(message: any): void {
            delete message.request?.headers;
            delete message?.response?.headers;
            delete message?.response?.config;
            this.message = message;

            this.snackbarVisibility = false;
            this.dialog = true;
        },
        closeDialog(): void {
            this.message = '';
            this.dialog = false;
        }
    }
});
